import * as React from 'react'
import {
    Flex,
} from '@chakra-ui/react'
import { ProductCard } from './ProductCard'

export default function ProductListing({ product }) {
    
    return (
        <Flex w="100%" flexDirection="column" h="100%">
            {product.map((p, index) => (
                <ProductCard product={p} key={p.id} eager={index === 0} />
            ))}
        </Flex>
    )
} 