import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
    Box,
    Flex,
    Heading,
    Text,
    Button
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'
import { AddToCart } from './AddToCart'
import useStore from '../../context/StoreContext'

export function ProductCard({ product }) {
    const { addVariantToCart } = useStore()
    console.log(product)
    const hasImage = product.media.length > 0;

    const available = product.variants[0].availableForSale === true;

    return (
        <Box w="100%" p="2" flexDirection="column">
            <Flex borderRadius="md" boxShadow="md" p="4" flexDirection={{base: "column", md: "row"}} w="100%" h="100%" justifyContent="space-between" alignItems="center">
                {hasImage 
                    ? (
                         <Box w={{base: "100%", md: "20%"}} pr={{base: 0, md: 2}}>
                             <GatsbyImage
                                //alt={firstImage?.altText ?? title}
                                image={getImage(product.media[0].image.localFile)}
                        />
                         </Box>
                    ) : (
                       null
                    )
                }
                <Box w={{base: "100%", md: "60%"}}>
                    <Heading as="h4" variant="h4" fontSize="1.5rem" textAlign={{base: 'center', md: 'left'}}>{product.title}</Heading>
                    <Text textAlign={{base: 'center', md: 'left'}}>{product.description}</Text>
                </Box>
                
                
                <Box textAlign="center" marginTop="auto">
                <Text  textAlign={{base: 'center', md: 'left'}} fontWeight="bold" fontSize="1.75rem" color="plein-air.600">{`$${product.priceRangeV2.maxVariantPrice.amount}`}</Text>
                    <Button onClick={() => addVariantToCart(product, 1)} variant="plein-air" disabled={!available}>
                        {available ? 'Add to Cart' : 'Sold Out'}
                    </Button>
                </Box>
            </Flex>
        </Box>
    )
}