import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import {
  Heading,
  Container,
  Box,
  Flex,
} from '@chakra-ui/react'

import { PrismicRichText, SliceZone } from '@prismicio/react'
import { components } from "../../components/Slices"
import PageSubMenu from "../../components/PageSubMenu/PageSubMenu"
import ProductListing from "../../components/Product/ProductListing"
import Cart from '../../components/Product/Cart'
//import { StoreContext } from "../../context/StoreContext"
import { formatPrice } from "../../utils/format-price"


const RegisterPage = ({ data, location }) => {
  const doc = data.prismicPleinAirPage.data;
  const { nodes } = data.allShopifyProduct
  console.log(doc)



  return (
    <Layout>
     {doc.submenu.document !== null &&
      <PageSubMenu 
        menuItems={doc.submenu.document.data.page_submenu}
        location={location}
      />}
     <main>
        <Container maxW="container.xl">
          <Flex alignItems="center" textAlign="center" flexDirection="column">
            <Box mt="12">
              <Heading as="h1" fontSize="5xl" mb="4">
                {doc.title.text}
              </Heading>
              <Box textAlign={{base: "center", md: "left"}}>
                <PrismicRichText field={doc.page_content.richText} />
              </Box>
            </Box>
          </Flex>
          <Flex w="100%" flexDirection={{base: 'column-reverse', md: 'row'}}>
            <Box w={{base: "100%", md: "60%"}} p="2">
              <ProductListing product={nodes} />
            </Box>
            <Box w={{base: "100%", md: "40%"}} p="2">
              <Cart item={ nodes }/>
            </Box>
            
          </Flex>
        </Container>
        <SliceZone slices={doc.body} components={components} />
      </main>
    </Layout>
  )
}

export const query = graphql`
query {
  prismicPleinAirPage(uid: {eq: "register"}) {
    uid
    data {
      title {
        text
      }
      body {
        ... on PrismicPleinAirPageDataBodyNewsletterSignup {
          id
          slice_type
          primary {
            newsletter_signup_title {
              text
            }
            newsletter_signup_subtitle {
              text
            }
            newsletter_signup_button_label
            newsletter_signup_background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicPleinAirPageDataBodyCallToAction {
          id
          slice_type
          primary {
            link_label
            link {
              url
            }
            call_to_action_title {
              text
            }
            call_to_action_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            call_to_action_content {
              richText
            }
          }
        }
        ... on PrismicPleinAirPageDataBodyRichText {
          id
          slice_type
          items {
            content {
              richText
            }
          }
        }
      }
      submenu {
        document {
          ... on PrismicPageSubmenu {
            id
            data {
              page_submenu {
                menu_item_link {
                  url
                }
                menu_item_label
              }
            }
          }
        }
      }
      page_content {
        richText
      }
    }
  }
  allShopifyProduct(sort: {fields: title, order: DESC}
    filter: {collections: {elemMatch: {title: {eq: "Plein Air"}}}}
  ) {
    nodes {
      variants {
        shopifyId
        availableForSale
      }
      description
      handle
      title
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
      }
      media {
        ... on ShopifyMediaImage {
          id
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
        }
      }
    }
  }
}
`

export default RegisterPage

export const Head = ({ data }) => <title>{data.prismicPleinAirPage.data.title.text}</title>
